const releases = [
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/36468346",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/36468346/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/36468346/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.21.0",
     "id": 36468346,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTM2NDY4MzQ2",
     "tag_name": "v0.21.0",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.21.0",
     "draft": false,
     "prerelease": false,
     "created_at": "2021-01-13T21:57:24Z",
     "published_at": "2021-01-15T19:52:11Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.21.0",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.21.0",
     "body": "Bitcoin Core version 0.21.0 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.21.0/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.21.0.md\r\n\r\nPreferably use the above download link, not the links provided by GitHub to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not."
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/29200501",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/29200501/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/29200501/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.20.1",
     "id": 29200501,
     "author": {
      "login": "MarcoFalke",
      "id": 6399679,
      "node_id": "MDQ6VXNlcjYzOTk2Nzk=",
      "avatar_url": "https://avatars.githubusercontent.com/u/6399679?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/MarcoFalke",
      "html_url": "https://github.com/MarcoFalke",
      "followers_url": "https://api.github.com/users/MarcoFalke/followers",
      "following_url": "https://api.github.com/users/MarcoFalke/following{/other_user}",
      "gists_url": "https://api.github.com/users/MarcoFalke/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/MarcoFalke/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/MarcoFalke/subscriptions",
      "organizations_url": "https://api.github.com/users/MarcoFalke/orgs",
      "repos_url": "https://api.github.com/users/MarcoFalke/repos",
      "events_url": "https://api.github.com/users/MarcoFalke/events{/privacy}",
      "received_events_url": "https://api.github.com/users/MarcoFalke/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTI5MjAwNTAx",
     "tag_name": "v0.20.1",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.20.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2020-07-31T12:04:48Z",
     "published_at": "2020-08-02T07:52:16Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.20.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.20.1",
     "body": "Bitcoin Core version 0.20.1 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.20.1/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.20.1.md\r\n\r\nPreferably use the above download link, not the links provided by GitHub to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not."
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/27174292",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/27174292/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/27174292/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.20.0",
     "id": 27174292,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTI3MTc0Mjky",
     "tag_name": "v0.20.0",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.20.0",
     "draft": false,
     "prerelease": false,
     "created_at": "2020-06-02T07:45:19Z",
     "published_at": "2020-06-03T11:28:21Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.20.0",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.20.0",
     "body": "Bitcoin Core version 0.20.0 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.20.0/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.20.0.md\r\n\r\nPreferably use the above download link, not the links provided by GitHub to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not."
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/24363833",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/24363833/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/24363833/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.19.1",
     "id": 24363833,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTI0MzYzODMz",
     "tag_name": "v0.19.1",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.19.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2020-03-04T12:15:29Z",
     "published_at": "2020-03-09T19:58:58Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.19.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.19.1",
     "body": "Bitcoin Core version 0.19.1 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.19.1/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.19.1.md\r\n\r\nPreferably use the above download link, not the links provided by GitHub to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not."
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/21713893",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/21713893/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/21713893/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.19.0.1",
     "id": 21713893,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTIxNzEzODkz",
     "tag_name": "v0.19.0.1",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.19.0.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2019-11-18T13:17:18Z",
     "published_at": "2019-11-24T13:26:31Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.19.0.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.19.0.1",
     "body": "Bitcoin Core version 0.19.0.1 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.19.0.1/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.19.0.1.md\r\n\r\nPreferably use the above download link, not the links provided by GitHub to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/19200162",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/19200162/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/19200162/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.18.1",
     "id": 19200162,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTE5MjAwMTYy",
     "tag_name": "v0.18.1",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.18.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2019-08-02T20:41:45Z",
     "published_at": "2019-08-09T18:53:34Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.18.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.18.1",
     "body": "Bitcoin Core version 0.18.1 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.18.1/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.18.1.md\r\n\r\nPreferably use the above download link, not the links provided by GitHub to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/17440934",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/17440934/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/17440934/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.18.0",
     "id": 17440934,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTE3NDQwOTM0",
     "tag_name": "v0.18.0",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.18.0",
     "draft": false,
     "prerelease": false,
     "created_at": "2019-04-30T12:14:06Z",
     "published_at": "2019-05-18T18:22:20Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.18.0",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.18.0",
     "body": "Bitcoin Core version 0.18.0 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.18.0/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.18.0.md\r\n\r\nPreferably use the above download link, not the links provided by GitHub to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/14691582",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/14691582/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/14691582/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.17.1",
     "id": 14691582,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTE0NjkxNTgy",
     "tag_name": "v0.17.1",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.17.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2018-12-22T09:08:12Z",
     "published_at": "2018-12-25T09:36:21Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.17.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.17.1",
     "body": "Bitcoin Core version 0.17.1 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.17.1/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.17.1.md\r\n\r\nPreferably use the above download link, not the links provided by GitHub to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/13847617",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/13847617/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/13847617/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.17.0.1",
     "id": 13847617,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTEzODQ3NjE3",
     "tag_name": "v0.17.0.1",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.17.0.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2018-10-26T11:05:42Z",
     "published_at": "2018-11-06T08:34:16Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.17.0.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.17.0.1",
     "body": "Bitcoin Core version 0.17.0.1 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.17.0.1/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.17.0.1.md\r\n\r\nPreferably use the above download link, not the links provided by GitHub to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/13218409",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/13218409/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/13218409/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.17.0",
     "id": 13218409,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTEzMjE4NDA5",
     "tag_name": "v0.17.0",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.17.0",
     "draft": false,
     "prerelease": false,
     "created_at": "2018-10-01T10:49:12Z",
     "published_at": "2018-10-03T09:22:37Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.17.0",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.17.0",
     "body": "Bitcoin Core version 0.17.0 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.17.0/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.17.0.md\r\n\r\nPreferably use the above download link, not the links provided by GitHub to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/13152835",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/13152835/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/13152835/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.15.2",
     "id": 13152835,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTEzMTUyODM1",
     "tag_name": "v0.15.2",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.15.2",
     "draft": false,
     "prerelease": false,
     "created_at": "2018-09-19T11:51:30Z",
     "published_at": "2018-09-28T15:30:19Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.15.2",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.15.2",
     "body": "Bitcoin Core version 0.15.2 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.15.2/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.15.2.md\r\n\r\nPreferably use the above download link, not the below links to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/13152826",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/13152826/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/13152826/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.14.3",
     "id": 13152826,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTEzMTUyODI2",
     "tag_name": "v0.14.3",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.14.3",
     "draft": false,
     "prerelease": false,
     "created_at": "2018-09-20T17:15:35Z",
     "published_at": "2018-09-28T15:29:44Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.14.3",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.14.3",
     "body": "Bitcoin Core version 0.14.3 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.14.3/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.14.3.md\r\n\r\nPreferably use the above download link, not the below links to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/12968205",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/12968205/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/12968205/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.16.3",
     "id": 12968205,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTEyOTY4MjA1",
     "tag_name": "v0.16.3",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.16.3",
     "draft": false,
     "prerelease": false,
     "created_at": "2018-09-18T00:24:44Z",
     "published_at": "2018-09-18T21:14:45Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.16.3",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.16.3",
     "body": "Bitcoin Core version 0.16.3 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.16.3/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.16.3.md\r\n\r\nPreferably use the above download link, not the below links to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/12154192",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/12154192/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/12154192/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.16.2",
     "id": 12154192,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTEyMTU0MTky",
     "tag_name": "v0.16.2",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.16.2",
     "draft": false,
     "prerelease": false,
     "created_at": "2018-07-26T20:48:25Z",
     "published_at": "2018-07-29T17:04:40Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.16.2",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.16.2",
     "body": "Bitcoin Core version 0.16.2 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.16.2/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.16.2.md\r\n\r\nPreferably use the above download link, not the below links to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/11504419",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/11504419/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/11504419/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.16.1",
     "id": 11504419,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTExNTA0NDE5",
     "tag_name": "v0.16.1",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.16.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2018-06-13T12:44:57Z",
     "published_at": "2018-06-15T16:35:36Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.16.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.16.1",
     "body": "Bitcoin Core version 0.16.1 is now available from:\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.16.1/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.16.1.md\r\n\r\nPreferably use the above download link, not the below links to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not.\r\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/9833325",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/9833325/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/9833325/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.16.0",
     "id": 9833325,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTk4MzMzMjU=",
     "tag_name": "v0.16.0",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.16.0",
     "draft": false,
     "prerelease": false,
     "created_at": "2018-02-22T19:25:44Z",
     "published_at": "2018-02-26T11:59:39Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.16.0",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.16.0",
     "body": "Bitcoin Core version 0.16.0 is now available from:\r\n\r\nhttps://bitcoin.org/bin/bitcoin-core-0.16.0/\r\n\r\nand\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.16.0/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.16.0.md\r\n\r\nPreferably use the above download link, not the below links to download the source tarball, as the release tarballs are generated deterministically whereas GitHub's are not."
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/8478211",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/8478211/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/8478211/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.15.1",
     "id": 8478211,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTg0NzgyMTE=",
     "tag_name": "v0.15.1",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.15.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2017-11-09T20:24:56Z",
     "published_at": "2017-11-11T14:24:25Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.15.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.15.1",
     "body": "Bitcoin Core version 0.15.1 is now available from:\r\n\r\nhttps://bitcoin.org/bin/bitcoin-core-0.15.1/\r\n\r\nand\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.15.1/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.15.1.md\r\n\r\nPreferably use the above download link, not the below links to download the source tarball, as the release tarballs are generated deterministically and GitHub's are not."
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/7807508",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/7807508/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/7807508/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.15.0.1",
     "id": 7807508,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTc4MDc1MDg=",
     "tag_name": "v0.15.0.1",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.15.0.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2017-09-15T14:05:26Z",
     "published_at": "2017-09-19T13:05:50Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.15.0.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.15.0.1",
     "body": "Bitcoin Core version 0.15.0.1 is now available from:\r\n\r\nhttps://bitcoin.org/bin/bitcoin-core-0.15.0.1/\r\n\r\nand\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.15.0.1/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.15.0.1.md\r\n\r\nPreferably use the above download link, not the below links to download the source tarball, as the release tarballs are generated deterministically and GitHub's are not."
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/7755143",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/7755143/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/7755143/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.15.0",
     "id": 7755143,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTc3NTUxNDM=",
     "tag_name": "v0.15.0",
     "target_commitish": "0.15",
     "name": "Bitcoin Core 0.15.0",
     "draft": false,
     "prerelease": false,
     "created_at": "2017-09-10T22:14:20Z",
     "published_at": "2017-09-14T14:34:57Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.15.0",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.15.0",
     "body": "Bitcoin Core version 0.15.0 is now available from:\r\n\r\nhttps://bitcoin.org/bin/bitcoin-core-0.15.0/\r\n\r\nand\r\n\r\nhttps://bitcoincore.org/bin/bitcoin-core-0.15.0/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.15.0.md\r\n\r\nPreferably use the above download link, not the below links to download the source tarball, as the release tarballs are generated deterministically and GitHubs's are not."
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/6749467",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/6749467/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/6749467/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.14.2",
     "id": 6749467,
     "author": {
      "login": "MarcoFalke",
      "id": 6399679,
      "node_id": "MDQ6VXNlcjYzOTk2Nzk=",
      "avatar_url": "https://avatars.githubusercontent.com/u/6399679?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/MarcoFalke",
      "html_url": "https://github.com/MarcoFalke",
      "followers_url": "https://api.github.com/users/MarcoFalke/followers",
      "following_url": "https://api.github.com/users/MarcoFalke/following{/other_user}",
      "gists_url": "https://api.github.com/users/MarcoFalke/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/MarcoFalke/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/MarcoFalke/subscriptions",
      "organizations_url": "https://api.github.com/users/MarcoFalke/orgs",
      "repos_url": "https://api.github.com/users/MarcoFalke/repos",
      "events_url": "https://api.github.com/users/MarcoFalke/events{/privacy}",
      "received_events_url": "https://api.github.com/users/MarcoFalke/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTY3NDk0Njc=",
     "tag_name": "v0.14.2",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.14.2",
     "draft": false,
     "prerelease": false,
     "created_at": "2017-06-15T12:08:29Z",
     "published_at": "2017-06-18T08:42:37Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.14.2",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.14.2",
     "body": "Bitcoin Core version 0.14.2 is now available from:\r\n\r\n  https://bitcoin.org/bin/bitcoin-core-0.14.2/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.14.2.md"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/6156951",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/6156951/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/6156951/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.14.1",
     "id": 6156951,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTYxNTY5NTE=",
     "tag_name": "v0.14.1",
     "target_commitish": "master",
     "name": "Bitcoin Core 0.14.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2017-04-20T19:45:34Z",
     "published_at": "2017-04-22T14:19:24Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.14.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.14.1",
     "body": "Bitcoin Core version 0.14.1 is now available from:\r\n\r\n  https://bitcoin.org/bin/bitcoin-core-0.14.1/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.14.1.md"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/5678033",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/5678033/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/5678033/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.14.0",
     "id": 5678033,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTU2NzgwMzM=",
     "tag_name": "v0.14.0",
     "target_commitish": "master",
     "name": "Bitcoin Core v0.14.0",
     "draft": false,
     "prerelease": false,
     "created_at": "2017-03-07T10:52:21Z",
     "published_at": "2017-03-08T15:20:25Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.14.0",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.14.0",
     "body": "Bitcoin Core version 0.14.0 is now available from:\r\n\r\n  https://bitcoin.org/bin/bitcoin-core-0.14.0/\r\n\r\nFor the release notes please see the git repository:\r\n\r\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.14.0.md"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/5055113",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/5055113/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/5055113/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.13.2",
     "id": 5055113,
     "author": {
      "login": "laanwj",
      "id": 126646,
      "node_id": "MDQ6VXNlcjEyNjY0Ng==",
      "avatar_url": "https://avatars.githubusercontent.com/u/126646?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/laanwj",
      "html_url": "https://github.com/laanwj",
      "followers_url": "https://api.github.com/users/laanwj/followers",
      "following_url": "https://api.github.com/users/laanwj/following{/other_user}",
      "gists_url": "https://api.github.com/users/laanwj/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/laanwj/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/laanwj/subscriptions",
      "organizations_url": "https://api.github.com/users/laanwj/orgs",
      "repos_url": "https://api.github.com/users/laanwj/repos",
      "events_url": "https://api.github.com/users/laanwj/events{/privacy}",
      "received_events_url": "https://api.github.com/users/laanwj/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTUwNTUxMTM=",
     "tag_name": "v0.13.2",
     "target_commitish": "master",
     "name": "Bitcoin Core v0.13.2",
     "draft": false,
     "prerelease": false,
     "created_at": "2017-01-02T08:55:44Z",
     "published_at": "2017-01-03T10:48:14Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.13.2",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.13.2",
     "body": "Bitcoin Core version 0.13.2 is now available from:\n\n  https://bitcoin.org/bin/bitcoin-core-0.13.2/\n\nFor the release notes please see the git repository:\n\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.13.2.md\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540528",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540528/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/4540528/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.10.0",
     "id": 4540528,
     "author": {
      "login": "sipa",
      "id": 548488,
      "node_id": "MDQ6VXNlcjU0ODQ4OA==",
      "avatar_url": "https://avatars.githubusercontent.com/u/548488?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/sipa",
      "html_url": "https://github.com/sipa",
      "followers_url": "https://api.github.com/users/sipa/followers",
      "following_url": "https://api.github.com/users/sipa/following{/other_user}",
      "gists_url": "https://api.github.com/users/sipa/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/sipa/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/sipa/subscriptions",
      "organizations_url": "https://api.github.com/users/sipa/orgs",
      "repos_url": "https://api.github.com/users/sipa/repos",
      "events_url": "https://api.github.com/users/sipa/events{/privacy}",
      "received_events_url": "https://api.github.com/users/sipa/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTQ1NDA1Mjg=",
     "tag_name": "v0.10.0",
     "target_commitish": "master",
     "name": "Bitcoin Core v0.10.0",
     "draft": false,
     "prerelease": false,
     "created_at": "2015-02-13T09:24:49Z",
     "published_at": "2016-11-01T19:31:28Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.10.0",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.10.0",
     "body": "Bitcoin Core version 0.10.0 is now available from:\n\n  https://bitcoin.org/bin/0.10.0/\n\nFor the release notes please see the git repository:\n\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.10.0.md\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540523",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540523/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/4540523/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.10.1",
     "id": 4540523,
     "author": {
      "login": "sipa",
      "id": 548488,
      "node_id": "MDQ6VXNlcjU0ODQ4OA==",
      "avatar_url": "https://avatars.githubusercontent.com/u/548488?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/sipa",
      "html_url": "https://github.com/sipa",
      "followers_url": "https://api.github.com/users/sipa/followers",
      "following_url": "https://api.github.com/users/sipa/following{/other_user}",
      "gists_url": "https://api.github.com/users/sipa/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/sipa/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/sipa/subscriptions",
      "organizations_url": "https://api.github.com/users/sipa/orgs",
      "repos_url": "https://api.github.com/users/sipa/repos",
      "events_url": "https://api.github.com/users/sipa/events{/privacy}",
      "received_events_url": "https://api.github.com/users/sipa/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTQ1NDA1MjM=",
     "tag_name": "v0.10.1",
     "target_commitish": "master",
     "name": "Bitcoin Core v0.10.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2015-04-26T12:19:13Z",
     "published_at": "2016-11-01T19:31:06Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.10.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.10.1",
     "body": "Bitcoin Core version 0.10.1 is now available from:\n\n  https://bitcoin.org/bin/bitcoin-core-0.10.1/\n\nFor the release notes please see the git repository:\n\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.10.1.md\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540517",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540517/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/4540517/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.10.2",
     "id": 4540517,
     "author": {
      "login": "sipa",
      "id": 548488,
      "node_id": "MDQ6VXNlcjU0ODQ4OA==",
      "avatar_url": "https://avatars.githubusercontent.com/u/548488?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/sipa",
      "html_url": "https://github.com/sipa",
      "followers_url": "https://api.github.com/users/sipa/followers",
      "following_url": "https://api.github.com/users/sipa/following{/other_user}",
      "gists_url": "https://api.github.com/users/sipa/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/sipa/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/sipa/subscriptions",
      "organizations_url": "https://api.github.com/users/sipa/orgs",
      "repos_url": "https://api.github.com/users/sipa/repos",
      "events_url": "https://api.github.com/users/sipa/events{/privacy}",
      "received_events_url": "https://api.github.com/users/sipa/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTQ1NDA1MTc=",
     "tag_name": "v0.10.2",
     "target_commitish": "master",
     "name": "Bitcoin Core v0.10.2",
     "draft": false,
     "prerelease": false,
     "created_at": "2015-05-16T08:37:50Z",
     "published_at": "2016-11-01T19:30:39Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.10.2",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.10.2",
     "body": "Bitcoin Core version 0.10.2 is now available from:\n\n  https://bitcoin.org/bin/bitcoin-core-0.10.2/\n\nFor the release notes please see the git repository:\n\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.10.2.md\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540514",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540514/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/4540514/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.10.3",
     "id": 4540514,
     "author": {
      "login": "sipa",
      "id": 548488,
      "node_id": "MDQ6VXNlcjU0ODQ4OA==",
      "avatar_url": "https://avatars.githubusercontent.com/u/548488?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/sipa",
      "html_url": "https://github.com/sipa",
      "followers_url": "https://api.github.com/users/sipa/followers",
      "following_url": "https://api.github.com/users/sipa/following{/other_user}",
      "gists_url": "https://api.github.com/users/sipa/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/sipa/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/sipa/subscriptions",
      "organizations_url": "https://api.github.com/users/sipa/orgs",
      "repos_url": "https://api.github.com/users/sipa/repos",
      "events_url": "https://api.github.com/users/sipa/events{/privacy}",
      "received_events_url": "https://api.github.com/users/sipa/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTQ1NDA1MTQ=",
     "tag_name": "v0.10.3",
     "target_commitish": "master",
     "name": "Bitcoin Core v0.10.3",
     "draft": false,
     "prerelease": false,
     "created_at": "2015-10-14T08:02:20Z",
     "published_at": "2016-11-01T19:30:08Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.10.3",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.10.3",
     "body": "Bitcoin Core version 0.10.3 is now available from:\n\n  https://bitcoin.org/bin/bitcoin-core-0.10.3/\n\nFor the release notes please see the git repository:\n\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.10.3.md\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540510",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540510/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/4540510/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.11.1",
     "id": 4540510,
     "author": {
      "login": "sipa",
      "id": 548488,
      "node_id": "MDQ6VXNlcjU0ODQ4OA==",
      "avatar_url": "https://avatars.githubusercontent.com/u/548488?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/sipa",
      "html_url": "https://github.com/sipa",
      "followers_url": "https://api.github.com/users/sipa/followers",
      "following_url": "https://api.github.com/users/sipa/following{/other_user}",
      "gists_url": "https://api.github.com/users/sipa/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/sipa/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/sipa/subscriptions",
      "organizations_url": "https://api.github.com/users/sipa/orgs",
      "repos_url": "https://api.github.com/users/sipa/repos",
      "events_url": "https://api.github.com/users/sipa/events{/privacy}",
      "received_events_url": "https://api.github.com/users/sipa/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTQ1NDA1MTA=",
     "tag_name": "v0.11.1",
     "target_commitish": "master",
     "name": "Bitcoin Core v0.11.1",
     "draft": false,
     "prerelease": false,
     "created_at": "2015-10-14T08:03:45Z",
     "published_at": "2016-11-01T19:29:28Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.11.1",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.11.1",
     "body": "Bitcoin Core version 0.11.1 is now available from:\n\n  https://bitcoin.org/bin/bitcoin-core-0.11.1/\n\nFor the release notes please see the git repository:\n\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.11.1.md\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540502",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540502/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/4540502/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.11.2",
     "id": 4540502,
     "author": {
      "login": "sipa",
      "id": 548488,
      "node_id": "MDQ6VXNlcjU0ODQ4OA==",
      "avatar_url": "https://avatars.githubusercontent.com/u/548488?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/sipa",
      "html_url": "https://github.com/sipa",
      "followers_url": "https://api.github.com/users/sipa/followers",
      "following_url": "https://api.github.com/users/sipa/following{/other_user}",
      "gists_url": "https://api.github.com/users/sipa/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/sipa/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/sipa/subscriptions",
      "organizations_url": "https://api.github.com/users/sipa/orgs",
      "repos_url": "https://api.github.com/users/sipa/repos",
      "events_url": "https://api.github.com/users/sipa/events{/privacy}",
      "received_events_url": "https://api.github.com/users/sipa/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTQ1NDA1MDI=",
     "tag_name": "v0.11.2",
     "target_commitish": "master",
     "name": "Bitcoin Core v0.11.2",
     "draft": false,
     "prerelease": false,
     "created_at": "2015-11-12T20:03:22Z",
     "published_at": "2016-11-01T19:28:46Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.11.2",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.11.2",
     "body": "Bitcoin Core version 0.11.2 is now available from:\n\n  https://bitcoin.org/bin/bitcoin-core-0.11.2/\n\nFor the release notes please see the git repository:\n\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.11.2.md\n"
    },
    {
     "url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540486",
     "assets_url": "https://api.github.com/repos/bitcoin/bitcoin/releases/4540486/assets",
     "upload_url": "https://uploads.github.com/repos/bitcoin/bitcoin/releases/4540486/assets{?name,label}",
     "html_url": "https://github.com/bitcoin/bitcoin/releases/tag/v0.10.4",
     "id": 4540486,
     "author": {
      "login": "sipa",
      "id": 548488,
      "node_id": "MDQ6VXNlcjU0ODQ4OA==",
      "avatar_url": "https://avatars.githubusercontent.com/u/548488?v=4",
      "gravatar_id": "",
      "url": "https://api.github.com/users/sipa",
      "html_url": "https://github.com/sipa",
      "followers_url": "https://api.github.com/users/sipa/followers",
      "following_url": "https://api.github.com/users/sipa/following{/other_user}",
      "gists_url": "https://api.github.com/users/sipa/gists{/gist_id}",
      "starred_url": "https://api.github.com/users/sipa/starred{/owner}{/repo}",
      "subscriptions_url": "https://api.github.com/users/sipa/subscriptions",
      "organizations_url": "https://api.github.com/users/sipa/orgs",
      "repos_url": "https://api.github.com/users/sipa/repos",
      "events_url": "https://api.github.com/users/sipa/events{/privacy}",
      "received_events_url": "https://api.github.com/users/sipa/received_events",
      "type": "User",
      "site_admin": false
     },
     "node_id": "MDc6UmVsZWFzZTQ1NDA0ODY=",
     "tag_name": "v0.10.4",
     "target_commitish": "master",
     "name": "Bitcoin Core v0.10.4",
     "draft": false,
     "prerelease": false,
     "created_at": "2015-11-12T20:10:11Z",
     "published_at": "2016-11-01T19:27:11Z",
     "assets": [],
     "tarball_url": "https://api.github.com/repos/bitcoin/bitcoin/tarball/v0.10.4",
     "zipball_url": "https://api.github.com/repos/bitcoin/bitcoin/zipball/v0.10.4",
     "body": "Bitcoin Core version 0.10.4 is now available from:\n\n  https://bitcoin.org/bin/bitcoin-core-0.10.4/\n\nFor the release notes please see the git repository:\n\nhttps://github.com/bitcoin/bitcoin/blob/master/doc/release-notes/release-notes-0.10.4.md\n"
    }
   ];

export default releases;