import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import React from 'react';
import releases from '../hooks/btc-releases';

const TimelineTemplate = () => {
    console.log(releases);
    // const { title, subtitle } = useSiteMetadata();
    // const tags = useTagsList();
    return (
    <VerticalTimeline>
    {releases.map((rel) => (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
            date={rel.published_at}
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}>
        <h3 className="vertical-timeline-element-title">{rel.published_at}</h3>
        <h4 className="vertical-timeline-element-subtitle">{rel.name}</h4>
        <p>{rel.body}</p>
    </VerticalTimelineElement>
    ))}

    </VerticalTimeline>
    );
};

export default TimelineTemplate;